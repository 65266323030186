var timer;
var play_button_is_on = false;


var latitude=60.40;
var longitude=15.59;

var map=null;
var currentLayerNumber=0;
var marker=null;
//var graph=null;
//var container=null;
//var load_radar_image=true;


var radarData = null;

var image_urls=[];
var overlay_layer=null;
//var background_overlay_layer=null;
//var imageBounds = [[53.7578106079172,5.42488154317438], [70.0103447041092,29.6700207345862]];
//var imageBounds = [[46,0], [73,35]];



//var imageBounds = [[53.6813981284917,5.2849968932444], [70.0481652870767,29.7811924432583]];
var imageBounds = [[53.1554855,-7.9814033], [73.033726,40.6149334]];

//var leaflet_image_layer=null;
var latest_hour_image=null;
var grey_layers_array=[];


var slider_toast_color = "red";

//var set_graph_zero = true;
//var ios_fix=false;
   

var no_of_radar    = 0;
var no_of_nowcasts = 0;
var primary_source = null;
var label_radar_forecast = null;
var forecast_gradient;


var default_fill_opacity = 0.8;
var polyCoordsDMI = [
	[54.3, 8],
	[54.3, 17],
	[56.8, 17],
	[56.8, 13.5],
	[58.6, 13.5],
	[58.6, 8]
    ]; 

$(function(){
    if ( $( "#map" ).length) {
	    only_map();
	    initSlider();
    }
    
    
    //set_marker();
    
    //reload();
    loadMenu();
    click_event();
    //playerButton();
    //toogle_legend()
    
    //get_forecast()
    
    if ( $( "#map" ).length) {
	    
	    load_leaflet();	
	    
	    downloadRadarDatav2();
	    
	    set_init_map_position();
	    
	    /*weather_symbols();
	    map.on('dragend', function(e) {
	    weather_symbols();
	    console.log("karta fyttades")
	    });
	    */


//57.80965135970153|15.468750000000002
//56.8429636484013|14.370117187500002

	    /*
	    var polygon = L.polygon(polyCoordsDMI);//.addTo(map); 
	    if(isCoordInsidePolygon(latitude, longitude, polygon)) {
		toast("<img width='36' src='/icons/icon-denmark.svg'>DMI-radar finns nu!<br>Välj till höger.",true);
	    }
*/
	    setTimeout(function () { 
		    toast("Det finns nya radarbilder.<br>Klicka på uppdatera!",false);
	    }, 300000)
    }
    
});

function isCoordInsidePolygon(lat, lng, poly) {

	var polyPoints = poly.getLatLngs();
	
	var coordLat = lat;
	var coordLng = lng;
    
	var inside = false;
	for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    
	  var polyLat = polyPoints[i].lat;
	  var polyLng = polyPoints[i].lng;
    
	  var prevPolyLat = polyPoints[j].lat;
	  var prevPolyLng = polyPoints[j].lng;
	  
	  var intersect = ((polyLat > coordLat) != (prevPolyLat > coordLat))
		&& (coordLng < (prevPolyLng - polyLng) * (coordLat - polyLat) / (prevPolyLat - polyLat) + polyLng);
    
	  if (intersect) inside = !inside;
	}
    
	return inside;
    }


var marker_array=[];
var group_markers=null;
function weather_symbols(){
    
    
    //console.log(map.getBounds());
    //console.log(map.getBounds()._northEast.lat);
    apiNR=Math.floor((Math.random() * 5) + 2); 
    var bounds=map.getBounds();
    var request = $.ajax({
	     //url: 'http://observations.vackertvader.se/weather_station/get_observations_boundary_symbols',
	     //url: 'http://observations-api' + apiNR + '-eu.herokuapp.com/weather_station/get_observations_boundary_symbols',
	     url: '//api-observation.vackertvader.se/weather_station/get_observations_boundary_symbols',
	    dataType: 'jsonp',
	    data: {
		    limit: 400,
		    swpointy: bounds._southWest.lat,
		    swpointx: bounds._southWest.lng,
		    nepointy: bounds._northEast.lat,
		    nepointx: bounds._northEast.lng,
		    type:"snow"
		    //zoom: zoom,

	    },
	    success: function(data) {
		    //console.log(data);
		    
		    /*if(marker_array.length >0){
			    console.log("delete");	
			    console.log(marker_array.length);
			    for(i=0;i<marker_array.length;i++) {
				    map.removeLayer(marker_array[i]);
			    }  
			    marker_array=[];
		    }*/
		    if (group_markers){
			    group_markers.clearLayers();
			    //console.log("delete");
		    }
		    for(var m=0; m < data.observations.length; m++){
				  weather_symbols_draw(data.observations[m]);
		    }
		    group_markers = L.layerGroup(marker_array);
		    group_markers.addTo(map)
	    }
    });
}


//var search_group = new L.LayerGroup();
//var clickArr = new Array();
function weather_symbols_draw(observation){

    //if(marker_array.length >0){
    //	console.log("delete");	
    //}
	    
    
    var icon_url='//static.vackertvader.se/images/icons/shadowless/30x30_transparent/'+observation.icon
    var greenIcon = L.icon({
	  iconUrl: icon_url,
	  //shadowUrl: 'leaf-shadow.png',
    
	  iconSize:     [30, 30], // size of the icon
	  //shadowSize:   [50, 64], // size of the shadow
	  iconAnchor:   [15, 30], // point of the icon which will correspond to marker's location
	  //shadowAnchor: [4, 62],  // the same for the shadow
	  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor
    });
    var message="kl: " + observation.time;
    var marker=L.marker([observation.lat, observation.lng], {icon: greenIcon}).bindPopup(message)//.addTo(map);
    
    marker_array.push(marker);
    
    //marker.addTo(map);
    //marker.addTo(search_group)
    
    
}

function only_map(){
    if ($("#map").data("only-map")==true){
	    play_radar_animaton=false;
	    $(".topbar-wrapper").hide();
	    $(".legend-wrapper").hide();
	    $(".dropshadow").hide();
	    $(".bottom-row").hide();
	    $(".sources").hide();
	    //currentLayerNumber
    }
    

}
function set_init_map_position(){

    var cookie_position = Cookies.get('startposition')
    if (cookie_position){
	    //console.log(Cookies.get('startposition'))
	    var position = cookie_position.split("|")
	    latitude = position[0] 
	    longitude = position[1]
	    set_marker();
	    map.setView([latitude, longitude],7);

	    var polygon = L.polygon(polyCoordsDMI);//.addTo(map); 
	    if(isCoordInsidePolygon(latitude, longitude, polygon)) {
		toast("<img width='36' src='/icons/icon-denmark.svg'>Prova DMI:s radar!<br>Välj till höger.",true);
	    }
    }else if($("#map").data("lat") && $("#map").data("lng") ){
	    latitude=$("#map").data("lat");
	    longitude=$("#map").data("lng");
	    time=$("#map").data("time");
	    //console.log(latitude);
	    //console.log(longitude);
	    
	    //console.log("ad");
	    set_marker();
	    //map.flyTo([latitude, longitude],8);
	    //map.locate();
	    //map.setZoom(8);
	    map.setView([latitude, longitude],7);
	    //console.log(image_urls[currentLayerNumber])
	    //set_info_graph_and_update_layer_image(currentLayerNumber-1)
	    
	    
    }else{
	    getPosition();
    }

}

function get_forecast(){
    
	    var api = Math.floor(Math.random() * 7) + 1;
	    var request = $.ajax({
		    //url: 'http://apier.vackertvader.se:4012/radar',
		    url: '//regnradar-api.vackertvader.se/radar',
		    
		    dataType: 'jsonp',
		    data: {
			    lat: latitude,
			    lng:longitude
		    },
		    success: function(data) {
			    //console.log(data);
			    $( "#set-forecast" ).html(data.html);
		    },
		    error: function(e) {
			    //console.log("fel");
			    //console.log(e.message);
			    $( "#set-forecast" ).html("Ingen prognos för tillfället");
		    }
	    });
    
}

function toast(message, fadeout) {
    var toast = $('<div class="dropshadow ui-loader ui-body-e ui-corner-all toast-info">' + message + '</div>');

  toast.css({

     // display: 'block', 
	//background: '#fff',
	//opacity: 0.90, 
	//position: 'fixed',
	//padding: '7px',
	//'text-align': 'center',
	//width: '250px',
	left: ($(window).width() - 160) / 2
	//top: 200
  });

  var removeToast = function(){
		if(fadeout==false){
			    
			    $( ".reload-button-spinner" ).addClass( "fa-spin" );
			    $( ".toast-info" ).remove();
			    downloadRadarDatav2();
		}
		$(this).remove();

  };
  //console.log(fadeout);
  
  toast.click(removeToast);
  //$('.error').fadeIn(400).delay(3000).fadeOut(400);
  
  $(document.body).append(toast);

  //$toast.fadeIn(24000).delay(2000).appendTo($.mobile.pageContainer);
  toast.stop().fadeIn(400).delay(4500)//.fadeOut(1000, removeToast);
  if(fadeout==true)
		toast.fadeOut(400, removeToast);
}



var slider;
var slider_opacity;
var slider_pixel_position;


function initSlider(){
    
     //console.log("slider")
    
     slider_opacity = $('#slider_opacity').slider();
     
     
     slider_opacity.bind( "change", function(event, ui) {
	     //var opacity=event.currentTarget.value/100
	     default_fill_opacity=event.currentTarget.value/100
	     console.log(default_fill_opacity)
	    overlay_layer.setOpacity(default_fill_opacity);
	    $('.legend-opacity').css('opacity',(default_fill_opacity));
	    
	    for(var i in grey_layers_array){
		    grey_layers_array[i].setStyle({fillOpacity:default_fill_opacity});
	    }
	    
	});
     
	

	slider = $('#slider').slider();
    
	//console.log(no_of_radar)
	//console.log((24 / (47))*100)

	
     
	slider_pixel_position=$('.slider-time a');//$('.ui-btn.ui-slider-handle ')[0];
	slider_toast = $('#slider-toast-time');
	
	
	var oldLayer=200;
	$( window ).resize(function() {
	//$( window ).on( "orientationchange", function( event ) {
		    //
		    slider_pixel_position=$('.slider-time a');//$('.ui-btn.ui-slider-handle ')[0];
		    //slider_toast = $('#slider-toast-time');
		    set_info_graph_and_update_layer_image(currentLayerNumber);
		    //$('#slider').slider('refresh');
		    //console.log(slider_pixel_position.offset());
		    //alert( "This device is in " + event.orientation + " mode!" );
	   });
	
	
	slider.bind( "change", function(event, ui) {
	    currentLayerNumber = event.currentTarget.value;
	    //console.log(currentLayerNumber);

	    if(oldLayer != currentLayerNumber){
		    
	    
		    //console.log(currentLayerNumber);
		    //console.log(slider_position.style.left);
		    
		    
		    set_info_graph_and_update_layer_image(currentLayerNumber);
		    //overlay_layer.setUrl(image_urls[currentLayerNumber])
		    //leaflet_image_layer.attr('src',image_urls[currentLayerNumber]);
		    
		    oldLayer=currentLayerNumber;
		    //$('#precipitation-time').text(radarData.images[currentLayerNumber].time_local);
		    //$('.ui-slider a').css("left","10%");
		    //console.log($('.ui-btn.ui-slider-handle ').css("left"));
		    
		    //console.log(currentLayerNumber)
		    //console.log(no_of_radar)
		    if (no_of_radar != 0){
			    //console.log(currentLayerNumber)
			    //console.log(no_of_radar)
			    if (currentLayerNumber > (no_of_radar-1)   ){
				    //slider_toast.css({background: '#41B4E2'});
				    //$('.topbar-wrapper').css({background: '#41B4E2'});
				    $('#forecast-label').show(); //css({background: 'red'});
				    //$('.leaflet-image-layer').css({filter: 'blur(2px)'});
				    //now_cast_rectangle.setStyle({fillOpacity:0.1});
				    
			    }else if (currentLayerNumber < no_of_radar){
				    //slider_toast.css({background: '#1881BD'});
				    //$('.topbar-wrapper').css({background: 'white'});
				    $('#forecast-label').hide();
				    //$('.leaflet-image-layer').css({filter: 'blur(0px)'});
				    //$('#leaflet-image-layer').css({filter: blur(0px)});
				    //now_cast_rectangle.setStyle({fillOpacity:0});
				    
				    
			    }
		    }
	    }
	    
	    
	});
	    

	slider.bind( "slidestart", function(event, ui) {
	    stop_animation();
	});

     
	/*
	  var toast = $('<div id="slider-hand-o-down"><i class="fa fa-hand-o-down faa-horizontal animated  "></i></div>');

	toast.css({
    
		display: 'block', 
	    //background: '#fff',
	    opacity: 0.90, 
	    position: 'fixed',
	    //padding: '7px',
	    //'text-align': 'center',
	    width: '30px',
	    right: "55px",
	    bottom: '40px',
	    fontSize:"35px",
	    zIndex:10
	});
    
	var removeToast = function(){
		  $(this).remove();
	};
	toast.click(removeToast);
	$(document.body).append(toast);
	toast.stop().fadeIn(1000).delay(8000).fadeOut(1500);
	*/
	
	
	
}

	
	
	
    function click_event(){  
	 /*   $( ".radar-circle" ).click(function() {
		    
		    if (primary_source == "nordic"){
			    primary_source = "sweden"
			    toast("Sverige-radar från SMHI.<br>Saknar prognos.", true)
		    }else if(primary_source == "sweden"){
			primary_source = "denmark"
			toast("Danmark-radar.<br>Saknar prognos.", true)
			
		    }else{
			primary_source = "nordic"
			toast("Norden-radar.<br>Med prognos.", true)
		    }
		    $( ".radar-circle" ).find( "img" ).attr("src", "/icons/icon-" + primary_source +".svg");
		    //play_radar(null);
		    latest_hour_image = null
		    downloadRadarDatav2();
	    
	    });
*/
		$( ".radar-choose-region" ).click(function() {
			
			$.magnificPopup.close({
				items: {
				    src:"#radar-regions",
				    type: 'inline'
				}})

			primary_source= $(this).data('region');
			
			//if (primary_source == "sweden"){
				//toast("Sverige-radar från SMHI.<br>Saknar prognos.", true)
				//map.flyTo([63.13, 15.33],4);
			//}else 
			if(primary_source == "denmark"){
				//toast("Danmark-radar.<br>Saknar prognos.", true)

				//map.flyTo([55.8, 12.46],5);
				var polygon = L.polygon(polyCoordsDMI);//.addTo(map); 

				if(!isCoordInsidePolygon(latitude, longitude, polygon)) {
					map.flyTo([55.8, 12.46],5);
				}
				
			}//else{
				//toast("Norden-radar.<br>Med prognos.", true)
				//map.flyTo([64.03, 18.33],4);
			//}
			/**/
			
			//$(".svg-region" ).find( "img" ).attr("src", "/icons/icon-" + primary_source +".svg");
			//$("#radar-provider-wrapper").toggle()
			
			latest_hour_image = null
			downloadRadarDatav2();

		});

	    $( ".radar-circle-choose-menu-provider" ).click(function() {

		/**/
		//console.log(primary_source)
		//console.log($(this).data("region"))
		var svgs = $ (".radar-choose-region");
//console.log(svgs)
		svgs.each (function () {
			
			var region = $(this).data("region");
			console.log($(this))
			if( region == primary_source){
				$(this).css("background-color", "#035E91");
			}else{
				$(this).css("background-color", "#1881bd");
			};
		});

		//$("#radar-provider-wrapper").toggle()
		

		$.magnificPopup.open({
			items: {
			    src:"#radar-regions",
			    type: 'inline'
			},
			callbacks: {
			  open: function() {
			    // Will fire when this exact popup is opened
			    // this - is Magnific Popup object
			  },
			  close: function() {
			    //$("#set-forecast").html('<i class="fa fa-refresh fa-refresh-padding fa-spin forecast-spinner"> </i>');
			   // $("#share-loaded").hide();
			    //$("#share-loader").show()
			  }
			
			}
		    });

	    });

	    /*$( ".radar-choose-close" ).click(function() {
		$("#radar-provider-wrapper").hide()
	    });
	    */
	    $( "#faq" ).click(function() {
    
		    $.magnificPopup.open({
			items: {
			    src:"#info-modal",
			    type: 'inline'
			}
		    });
		    $(document).on('click', '.popup-modal-dismiss', function (e) {
			    e.preventDefault();
			    $.magnificPopup.close();
		    });
		    
	    });
	    

	    

	    $( "#about" ).click(function() {
    
		    $.magnificPopup.open({
			items: {
			    src:"#about-modal",
			    type: 'inline'
			}
		    });
		    $(document).on('click', '.popup-modal-dismiss', function (e) {
			    e.preventDefault();
			    $.magnificPopup.close();
		    });
		    
	    });
	    
	    
	    $( "#load-forecast" ).click(function() {
		    //$( ".forecast-circle").removeClass('hover');
		    //$( ".forecast-circle").removeClass('active');
		    if(longitude==15.59 && latitude==60.4){
			    toast("Vi saknar din position. <br/> Klicka på kartan!", true)
			    
		    }else{
			    get_forecast();
			    
			    $.magnificPopup.open({
				items: {
				    src:"#forecast-modal",
				    type: 'inline'
				},
				callbacks: {
				  open: function() {
				    // Will fire when this exact popup is opened
				    // this - is Magnific Popup object
				  },
				  close: function() {
				    $("#set-forecast").html('<i class="fa fa-refresh fa-refresh-padding fa-spin forecast-spinner"> </i>');
				  }
				
				}
			    });
			    
		    }	
		    //$(".forecast-circle").css("background","#1881bd");
	    });
	    
	    $( "#new-position" ).click(function() {
		    //$( ".find-me-circle").css('background-color', '#1881bd');
		    getPosition();
		    //$(".find-me-circle").css("background","#1881bd"); 
	    });
	    
	    //$("#play-button").click(function() {
	    $(".bottom-play").click(function() {
		    play_radar(null);
	    });

	    $("#home-button").click(function() {
		    
		    if(longitude==15.59 && latitude==60.4){
			    toast("Vi saknar din position. <br/> Klicka på kartan!", false)
		    }else if(Cookies.get('startposition')){
			    $.magnificPopup.open({
				    items: {
					  src:"#start-position-delete-modal",
					  type: 'inline'
				    }
			    });
		    }else{
			    $.magnificPopup.open({
				    items: {
					  src:"#start-position-set-modal",
					  type: 'inline'
				    }
			    });
			    //alert(Cookies.get('startposition') )
			    //toast("Vill du sätta den här platsen som din startposition",true)
		    }
		    $(document).on('click', '.popup-modal-delete-cookie', function (e) {
			    Cookies.remove('startposition');
			    e.preventDefault();
			    $.magnificPopup.close();
			    //location.reload();
			    toast("Förinställd plats är rensad",true)
		    });

		    $(document).on('click', '.popup-modal-set-cookie', function (e) {
			    Cookies.set('startposition', latitude + "|" + longitude , { expires: 180 });
			    e.preventDefault();
			    $.magnificPopup.close();
			    toast("Startposition satt",true)
			    //location.reload();
		    });

	    });
	    
	    
	    $( "#start-position-info" ).click(function() {
    
		    $.magnificPopup.open({
			items: {
			    src:"#start-position-info-modal",
			    type: 'inline'
			}
		    });
		    $(document).on('click', '.popup-modal-dismiss', function (e) {
			    e.preventDefault();
			    $.magnificPopup.close();
		    });
		    $(document).on('click', '.popup-modal-clear-cookie', function (e) {
			    Cookies.remove('startposition');
			    e.preventDefault();
			    $.magnificPopup.close();
			    location.reload();
		    });
		    
	    });


	    
    //function reload()
    //{	
	    var ads_reloaded=false;
	    $( "#reload-button" ).click(function() {
		    //$( "#reload-button").removeClass('reload-circle');
		    $( ".reload-circle").removeClass('hover');
		    //$('#reload-button').unbind('mouseenter mouseleave');
		    
		    $( ".reload-button-spinner" ).addClass( "fa-spin" );
		    $( ".toast-info" ).remove();
		    //$(".reload-circle").css("background","#1881bd"); 
		    downloadRadarDatav2();
		    
		    if(ads_reloaded==false){
			    console.log("reload");
			    /*if(typeof adslots2 === 'undefined'){
				    googletag.pubads().refresh();
			    } else{
				    googletag.pubads().clear();
				    googletag.pubads().refresh([adslots1]);
			    }*/
			    Quickwrap.refresh();
			    ads_reloaded=true;
			    setTimeout(function() {ads_reloaded=false; }, 2 * 60 * 1000);
		    }
	    });
    //}
    
    
    
    //function toogle_legend()
    //{
	    $( ".legend-button-wrapper" ).click(function() {
		    $( ".legend-wrapper" ).slideToggle(400);
		    $( ".legend-wrapper" ).css({
			    left: ($(window).width() - 230) / 2
		    });
	    });
    //}
	    /*$('#share-input-url').on('click', function(){*/
	    $('#share-input-url').click(function(e){
		    $(this)[0].setSelectionRange(0, 9999);
	    })
    
	    $( "#share-animation" ).click(function() {
		    //console.log("klick");
		    //console.log(latitude);
		    //console.log(longitude);
		    //console.log(map.getZoom());
		    
		    $.magnificPopup.open({
				items: {
				    src:"#share-modal",
				    type: 'inline'
				},
				callbacks: {
				  open: function() {
				    // Will fire when this exact popup is opened
				    // this - is Magnific Popup object
				  },
				  close: function() {
				    //$("#set-forecast").html('<i class="fa fa-refresh fa-refresh-padding fa-spin forecast-spinner"> </i>');
				    $("#share-loaded").hide();
				    $("#share-loader").show()
				  }
				
				}
			    });
		    
		    var request = $.ajax({
			    //url: 'http://apier.vackertvader.se:4012/radar',
			    url: '//dela.regnradar.se/animation/index',
			    dataType: 'jsonp',
			    data: {
				    lat: latitude,
				    long:longitude,
				    zoom:map.getZoom()
			    },
			    success: function(data) {
				    //console.log(data.file);
				    
				    $("#share-loaded").show();
				    $("#share-loader").hide();
				    
				    
				    $("#img-animated-url").attr("src", data.file);
				    $("#img-animated-url").width($("#share-loaded").width()-20);
				    $("#fb-share-url").attr("href", "http://www.facebook.com/sharer/sharer.php?u="+data.url);
				    $("#share-input-url").attr("value", data.url);
				    if ((window.location.href).indexOf("dela") > -1){
					    ChangeUrl(data.key,  data.key);
				    }else{
					    ChangeUrl(data.key, "dela/" + data.key);
				    }
				    //location.hash = "/parameter1=987&parameter2=zyx";
				    
				    //console.log(window.location.href);
				    //location.hash = "/dela/" + data.key;
				    //fb-share-button
				    //console.log(data.key);
				    //console.log(data.url);
				    //$( "#set-forecast" ).html(data.html);
			    },
			    error: function(e) {
				    //console.log("fel");
				    //console.log(e.message);
				    //$( "#set-forecast" ).html("Ingen prognos för tillfället");
			    }
		    });
		    //$(".find-me-circle").css("background","#1881bd"); 
	    });
	    
	    
    }
    
    
     function ChangeUrl(page, url) {
	    if (typeof (history.pushState) != "undefined") {
		  var obj = { Page: page, Url: url };
		  history.pushState(obj, obj.Page, obj.Url);
	    } else {
		// alert("Browser does not support HTML5.");
	    }
	  }
    
    var play_paused = false;
    function play_radar(total_time_frames){ 
		    //$("#play-button").unbind('click');
		    //$('#slider-hand-o-down').hide();
		    if (play_button_is_on == true){
			    
			    clearTimeout(timer);
			    play_button_is_on = false;
			    $("#play-button-icon" ).addClass( "fa-play" );
			    $("#play-button-icon" ).removeClass( "fa-pause" );
		    }else{
			    $("#play-button-icon" ).addClass( "fa-pause" );
			    $("#play-button-icon" ).removeClass( "fa-play" );
			    //console.log("playing");
			    //var loop = radarData.images.length-1;
			    var current  = currentLayerNumber;
			    var total = radarData.images.length-1;
			    
			    if (total_time_frames){
				    //current=total-total_time_frames;
				    current=total_time_frames;
			    }else if (current==total){
				    current=0;
			    }
			    
			    
			    
			    play_button_is_on = true;
			    var looper = function(){
				    if (play_button_is_on == true){
					    
						    
					    if (current <= total ) {
						    
					    //	console.log(loop);
					    //	console.log(radarData.images[loop].time_local);
						    //$('#slider').attr("value",current);
						    
						    $('#slider').val(current).slider('refresh');
						    set_info_graph_and_update_layer_image(current);
						    currentLayerNumber=current;
						    
						    current++;
					    } else {
						    //console.log('Loop end.');
							    play_button_is_on = false;
						    
							    $("#play-button-icon" ).addClass( "fa-play" );
							    $("#play-button-icon" ).removeClass( "fa-pause" );
						    return;
					    }
					    
					    if (no_of_nowcasts != 0 && current == no_of_radar && play_paused == false){
				    
						    //timer = setTimeout(looper, 3000);
						    stop_animation();
						    play_paused = true;
					    }else{
						    play_paused = false;
						    timer = setTimeout(looper, 400);
					    }
				    }
			    };
			    
			    
		    looper();
		    }
			    
	    
    }


function getPosition(){
    
// Try HTML5 geolocation.
if (navigator.geolocation) {
	
  navigator.geolocation.getCurrentPosition(function(position) {
	  
    var pos = {
	lat: position.coords.latitude,
	lng: position.coords.longitude
    };
	latitude = position.coords.latitude;
	longitude = position.coords.longitude;

    //  alert(latitude)
	set_marker();
	map.flyTo([latitude, longitude],6);
	var polygon = L.polygon(polyCoordsDMI);//.addTo(map); 

	if(isCoordInsidePolygon(latitude, longitude, polygon)) {
	  toast("<img width='36' src='/icons/icon-denmark.svg'>Prova DMI:s radar!<br>Välj till höger.",true);
	}

	//setForecastURL();
	

  }, function() {
    //alert("Ingen position kunde fastställas");
    toast("Vi vet inte var du är! <br/> Zooma på kartan.", true)
     
  });
} else {
	
    //console.log("Din browser stödjer inte geoip");
    toast("Din webbläsare stödjer inte positionering! <br/> Zooma på kartan.", true)
  // Browser doesn't support Geolocation
}

}

function loadMenu(){
   $("#menu").mmenu({
	 "classes": "mm-slide mm-white",
     //  "offCanvas": {
	//    "zposition": "next"
     // },
	 "footer": {
	    "add": true,
	    "title": "En app av vackertv�der.se"
	 },
	 "navbar": {
	    "title": "Meny",
	    "add": true,
	    "update": true
	 }
   });
};
/*
function setForecastURL(){
    var api = Math.floor(Math.random() * 7) + 1 
    var request = $.ajax({
	     url: 'http://geonames-api'+api+'-eu.herokuapp.com/locations/find_by_coordinate',
	    dataType: 'jsonp',
	    data: {
		    lat: latitude,
		    lng:longitude
	    },
	    success: function(data) {
		    $("#forecast_url").attr("href",  "http://www.vackertvader.se/" + data[0].geoname.url);
		    $("#forecast_url").text(data[0].geoname.name);
		    $( "#reload-button" ).removeClass( "fa-spin" );

	    }
    });

}
*/

function downloadRadarDatav2(){
    
    var request = $.ajax({
	     //url: '//159.65.115.90:3000/radar/index',
	    url: '//api.regnradar.se/radar',
	    //url: '//207.154.196.77:3001/radar/nordic',
	    //url: '//159.89.6.91:4012/radar',
	    //url: 'http://37.139.21.186/radar/index',
	     //url: 'http://37.139.21.186:5000/radar/mockup',
	    dataType: 'jsonp',
	    data: {
		    //pos: latitude+","+longitude
	    },
	    success: function(data) {
		    //console.log(primary_source);
		    if (primary_source == null){
			    primary_source = data.primary
			    
		    }
		    //primary_source = "sweden"
		    
		    if (primary_source == "sweden"){
			    imageBounds = [[53.6813981284917,5.2849968932444], [70.0481652870767,29.7811924432583]]
		    }else if (primary_source == "nordic"){
			    //imageBounds = [[53.1554855,-7.9814033], [73.033726,40.6149334]];
			    //imageBounds = [[53.07854855,-8.19014033], [73.033726,40.8549334]];
			    imageBounds = [[53.0841628421789,-8.03410177882381],[73.0558890312605,40.787194494374]];
		    }else if (primary_source == "denmark"){
				imageBounds = [[52.1612155611617, 2.99999999658801],[60.2118665106867, 20.7312188688115]]
		    }
		    //$( ".svg-region" ).find( "img" ).attr("src", "/icons/icon-" + primary_source +".svg");
		    $( ".svg-region" ).attr("src", "/icons/icon-" + primary_source +".svg");

		    radarData=data[primary_source];
		    

		    //$(".api-link").attr("href",  "http://37.139.21.186:5000/radar/index?pos="+latitude+","+longitude);
		    
		    
		    no_of_radar = data[primary_source].no_of_radar;
		    no_of_nowcasts = data[primary_source].no_of_nowcasts;
		    //console.log("label_radar_forecast: " + label_radar_forecast)
		    //console.log("primary_source: " + primary_source)
		    //if(label_radar_forecast == null && primary_source == "nordic"){
		    if(primary_source == "nordic"){
			    //label_radar_forecast = $('<label id = "slider-label-forecast">Prognos</label>').css('left','Calc( ((100% - 20px)/'+ (no_of_radar + no_of_nowcasts) + ") * " + no_of_radar + ' + 5px )');
			    
			    //label_radar_forecast = "exist";
			    $("#slider-label-forecast"   ).css('left','Calc( ((100% - 20px)/'+ (no_of_radar + no_of_nowcasts) + ") * " + no_of_radar + ' + 5px )');
			    $("#slider-forecast-gradient").css('left','Calc( ((100% - 20px)/'+ (no_of_radar + no_of_nowcasts) + ") * " + no_of_radar + ')');
			    //label_radar_forecast = $('<label id = "slider-label-forecast">Prognos</label>').css('left','Calc('+ 50 + '% + 7px)');
			    //$( "#slider" ).append(label_radar_forecast);
			    

			    $( "#slider-label-forecast" ).show();
			    $( "#slider-forecast-gradient" ).show();
			    
			    $( "#smhi-source" ).hide();
			    $( "#met-source" ).show();
			    $( "#dmi-source" ).hide()
		    }else if(primary_source == "sweden"){
			    $( "#slider-label-forecast" ).hide();
			    $( "#slider-forecast-gradient" ).hide();

			    $( "#smhi-source" ).show();
			    $( "#met-source" ).hide();
			    $( "#dmi-source" ).hide()
		    }else if(primary_source == "denmark"){
				$( "#slider-label-forecast" ).hide();
				$( "#slider-forecast-gradient" ).hide();

				$( "#smhi-source" ).hide();
				$( "#met-source" ).hide();
				$( "#dmi-source" ).show()
		    }/*else if(primary_source == "nordic"){
			    $( "#slider-label-forecast" ).show();
			    $( "#slider-forecast-gradient" ).show();
		    }*/
		    //currentLayerNumber=no_of_radar - 12;
		    //console.log("------")
		    //console.log(primary_source)
		    //console.log("currentLayerNumber: " + currentLayerNumber)
		    //console.log("no_of_radar: " + no_of_radar)
		    //console.log("no_of_nowcasts: " + no_of_nowcasts)
		    if (currentLayerNumber >= no_of_radar){
			    //currentLayerNumber=12;
			    currentLayerNumber=no_of_radar-1;
		    }else if (currentLayerNumber == 0){
			    currentLayerNumber=12;
		    }
		    if(primary_source == "denmark")
		    	currentLayerNumber = 5;   
		    
		   // console.log(currentLayerNumber )
		   // console.log(no_of_radar )
		   
		   if (currentLayerNumber > (radarData.images.length-1)){
		   	currentLayerNumber = radarData.images.length-1;
		   }
		    
		    reinit_radar_overlay(radarData.images[currentLayerNumber].image_url);

		    
		    

		    //$( "#slider" ).slider( "disable" );
		    //$('#slider').attr("value",currentLayerNumber);
		    $('#slider').attr("max",data[primary_source].images.length-1);
		    $('#slider').val(currentLayerNumber);
		    $('#slider').slider('refresh');
		    load_radar_imagev2(currentLayerNumber);
		    
		    
		    
		    
		    //$( "#slider" ).slider( "enable" );
		    

		    //currentLayerNumber=data[primary_source].images.length-1;
		    //$('#slider').attr("max",currentLayerNumber);
		    //$('#slider').attr("value",currentLayerNumber);
		    //$('#slider').slider('refresh');
		    
		    
	    }
    });

}

function load_radar_imagev2(index)
    {
    
	    //var forecast_index=radarData.images.length-1;//radarData.index_first_forecast;
	    //var forecast_index=currentLayerNumber;//radarData.index_first_forecast;
	    var forecast_index=index;
	    //if($("#map").data("time")){
	    //	forecast_index=forecast_index-$("#map").data("time");
	    //}

	    var new_image_time=new Date(radarData.images[forecast_index].time_utc);
	    
	    if(latest_hour_image){
		    if ( new_image_time > latest_hour_image){
			    toast("Hämtade nya radarbilder!",true);
		    }else{
			    toast("Inga nya radarbilder.",true);
		    }
		    
	    }
	    latest_hour_image=new_image_time;
	    
	    var init_overlay_url=radarData.images[forecast_index].image_url;
	    
	    currentLayerNumber=forecast_index;
	    $("#precipitation-time").text(radarData.images[forecast_index].time_local);
	    
	    var temp_image_urls = [];
	    for(var j=0;j<radarData.images.length;j++) {
		    var radar_image_color_url=radarData.images[j].image_url;
		    temp_image_urls.push(radar_image_color_url);
	    }
	    
	    image_urls = [];
	    image_urls=temp_image_urls;
	    
	    loadImages(temp_image_urls);
	    /*
	    if(background_overlay_layer==null){
		    background_overlay_layer=L.imageOverlay(radarData.radar_coverage_url, imageBounds,{
					    opacity:0.6
			    }).addTo(map);
		    $(".leaflet-image-layer").toggleClass('leaflet-image-layer leaflet-image-layer-background');
		    
	    }	
	    */
	    
	    if(overlay_layer==null){
		    add_radar_overlay(init_overlay_url);		
	    };
	    
	    
	    if (init_overlay_url){
		    //leaflet_image_layer.attr('src',init_overlay_url);
		    set_info_graph_and_update_layer_image(currentLayerNumber);
		    
		    //overlay_layer.setUrl(init_overlay_url)
	    }else{
		    //leaflet_image_layer = $('.leaflet-image-layer');	
	    }
	    //$('img').pixelate({value:0.9});
    }

    function stop_animation(){
	    clearTimeout(timer);
	    play_button_is_on = false;
	    $("#play-button-icon").addClass( "fa-play" );
	    $("#play-button-icon").removeClass( "fa-pause" );
	    $('#slider-hand-o-down').hide();

	}
    function reinit_radar_overlay(init_overlay_url){
	    stop_animation();

	    if (map != null){
		    for(var i in grey_layers_array) {
			    map.removeLayer(grey_layers_array[i]);
		    }
	    }
	    
	    if (overlay_layer != null ){
		    map.removeLayer(overlay_layer);
		    add_radar_overlay(init_overlay_url);
		    //console.log(init_overlay_url);
	    }
    }

    function add_radar_overlay(init_overlay_url){
	    
	    overlay_layer=L.imageOverlay(init_overlay_url, imageBounds,{
			    opacity:default_fill_opacity,
			    zIndex:2				
	    }).addTo(map);

	    $('.leaflet-image-layer').css({zIndex: '2'});
	    //alert($('.leaflet-image-layer').css("z-index"));

	     //var imageBounds = [[53.6813981284917,5.2849968932444], [70.0481652870767,29.7811924432583]];
	     //var imageBounds = [[53.1554871,-7.9813999], [73.0337210, 40.6149284]];

	    grey_layers_array.push(L.rectangle([[-85,-360],[85,imageBounds[0][1]]], {color: "#666666", weight: 0,opacity:0,fillOpacity:default_fill_opacity}).addTo(map));
	    grey_layers_array.push(L.rectangle([[-85,360],[85,imageBounds[1][1]]], {color: "#666666", weight: 0,opacity:0,fillOpacity:default_fill_opacity}).addTo(map));
	    grey_layers_array.push(L.rectangle([[-85,imageBounds[0][1]],[imageBounds[0][0],imageBounds[1][1]]], {color: "#666666", weight: 0,opacity:0,fillOpacity:default_fill_opacity}).addTo(map));
	    grey_layers_array.push(L.rectangle([[85,imageBounds[0][1]],[imageBounds[1][0],imageBounds[1][1]]], {color: "#666666", weight: 0,opacity:0,fillOpacity:default_fill_opacity}).addTo(map));



    }

    //var now_cast_rectangle;
    function load_leaflet()
    {

	    map = L.map('map', {
			    zoomControl: true,
			    minZoom: 0,
			    maxZoom: 13
		}).setView([latitude, longitude], 6);
	    map.on('click', onMapClick);
	    
	
	    // add an OpenStreetMap tile layer
	    //L.tileLayer( 'http://mt{s}.googleapis.com/vt?x={x}&y={y}&z={z}',{
	    //L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
	    //L.tileLayer('http://178.62.242.248/radarmap/{z}/{x}/{y}.png', {
	    //L.tileLayer('//radar-map-tiles.vackertvader.se/radarmap/{z}/{x}/{y}.png', {
	    L.tileLayer('//{s}-tiles.vackertvader.se/tiles/regnradar/{z}/{x}/{y}.png', {
	    //L.tileLayer('//{s}-tiles.vackertvader.se/tiles/regnradar_world_nordic/{z}/{x}/{y}.png', {
	    subdomains:'abcde'
	    }).addTo(map);
	    map.attributionControl.setPrefix('');//.addAttribution('');
	    
	    //$('.topbar-wrapper').css({background: '#46C403'})
	    //now_cast_rectangle = L.rectangle(imageBounds,{fillOpacity: 0,color:"#41B4E2",zindex:1}).addTo(map);


	    $('.leaflet-top').css('top', '50px');
	    

    }
    

    
    function onMapClick(e) {
	    //$( "#reload-button" ).addClass( "fa-spin" );
	    
	    latitude=e.latlng.lat;
	     longitude=e.latlng.lng;
	     set_marker();
	     
	     map.flyTo([latitude, longitude],map.getZoom());
	    //setForecastURL();
// 		downloadRadarData();
	    //$( "#reload-button" ).removeClass( "fa-spin" );
    }



    var imgList = [];
    $.extend({
	    preload: function(imgArr, option) {
		    var setting = $.extend({
			    init: function(loaded, total) {},
			    loaded: function(img, loaded, total) {},
			    loaded_all: function(loaded, total) {}
		    }, option);
		    var total = imgArr.length-1;
		    var loaded = 0;
		    

		    setting.init(0, total);
		    for(var i in imgArr) {
			    imgList.push($("<img />")
				    .attr("src", imgArr[i])
				    .load(function() {
					    loaded++;
					    setting.loaded(this, loaded, total);
					    if(loaded == total) {
						    setting.loaded_all(loaded, total);
					    }
				    })
			    );
		    }
		    
	    }
    });
    
    var play_radar_animaton=true;
    function loadImages(sources) {
	  $.preload(sources, {
		    init: function(loaded, total) {
			    
			    $( ".reload-button-spinner" ).addClass( "fa-spin" );
			    imgList = [];
			    $("#progressbar").width(0+'%');
			    
		    },
		    loaded: function(img, loaded, total) {
			    
			    var value=Math.round((loaded/total)*100);
			    $("#progressbar").width(value+'%');

			    if (value > 99)
				    $("#progressbar").width(0+'%');
				    $(".reload-button-spinner").removeClass("fa-spin");
		    },
		    loaded_all: function(loaded, total) {
			    
			    $("#progressbar").width(0+'%');
			    $(".reload-button-spinner").removeClass("fa-spin");
			    if (play_radar_animaton == true){
				    //play_radar(no_of_nowcasts + 11);
				    if (currentLayerNumber >= 5){ //12
					    play_radar(5);//12
				    }else{
					    play_radar(0);
				    }
				    play_radar_animaton=false;
				    //console.log("-----------")
				    //console.log(no_of_nowcasts + 12)
				    //console.log(no_of_nowcasts)
			    }
			    
				    
		    }
	    });
  }
	    
    function set_marker(){
		if(marker != null){
			marker.setLatLng(L.latLng(latitude, longitude));
		}else{
			var myIcon = L.icon({
			  iconUrl: '//static.vackertvader.se/images/googlemaps/google-map-marker-small.png',
			    iconSize: [17, 17]
		    });
		    marker=L.marker([latitude, longitude], {icon: myIcon}).addTo(map);
	    }
	}


   // var precipitation_time = $('#precipitation-time');
	    
    function set_info_graph_and_update_layer_image(showLayerNumber){
		    //leaflet_image_layer.attr('src',"");	
		    //leaflet_image_layer.attr('src',image_urls[showLayerNumber]);
		    if (image_urls.length > 0){
			    
			    
			    overlay_layer.setUrl(image_urls[showLayerNumber]);
			    //console.log(radarData.images[showLayerNumber].time_local);
			    $('#precipitation-time').text(radarData.images[showLayerNumber].time_local); 
			    //$('#precipitation-time').text(radarData.images[showLayerNumber].time_local + " " + radarData.images[showLayerNumber].type); 
			    //var slider_toast = $('#slider-toast-time');
			    slider_toast.text(radarData.images[showLayerNumber].time_local);
			    //slider_toast.text(radarData.images[showLayerNumber].time_local + " " + radarData.images[showLayerNumber].type);
			    slider_toast.css({
				    //display: 'absolute', 
				    //background: '#1881bd',
				    //opacity: 0.90, 
				    //position: 'absolute',
				    //padding: '5px',
				    //'text-align': 'center',
				    //width: '60px',
				    left: slider_pixel_position.offset().left-15,
				    //bottom: "70px",
				    //height:"22px",
				    //zIndex:6,
				    visibility:"visible"
				    
			    });
		    }
	}
    
  
    
